var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.url
    ? _c("div", { staticClass: "apod-wrapper" }, [
        _c(
          "a",
          {
            staticClass: "title",
            attrs: { href: _vm.link, target: "__blank", title: "View Article" },
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _c(
          "a",
          {
            staticClass: "picture",
            attrs: {
              href: _vm.hdurl,
              title: "View HD Image",
              target: "__blank",
            },
          },
          [_c("img", { attrs: { src: _vm.url, alt: _vm.title } })]
        ),
        _c("p", { staticClass: "copyright" }, [_vm._v(_vm._s(_vm.copyright))]),
        _c("p", { staticClass: "explanation" }, [
          _vm._v(_vm._s(_vm.truncatedExplanation)),
        ]),
        _c(
          "p",
          {
            staticClass: "expend-details-btn",
            on: { click: _vm.toggleShowFull },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.showFullExp
                    ? _vm.$t("widgets.general.show-less")
                    : _vm.$t("widgets.general.show-more")
                ) +
                " "
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }